import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
    scrollBehavior(to, from, savedPosition) {
        // `to` and `from` are both route locations
        // `savedPosition` can be null if there isn't one

        if (to.hash) {
            return new Promise((resolve, reject) => {
                let count = 0;
                let cleanUp;
                let headerHeight = 96;
                let totalOffsetTop = (element) => {
                    let top = 0
                    while (element) {
                        top += element.offsetTop;
                        element = element.offsetParent;
                    }
                    return top;
                };
                let interv = setInterval(() => {
                    let targ = document.getElementById(to.hash.slice(1));
                    if (targ) {
                        count++;
                        window?.scrollTo({
                            top: (totalOffsetTop(targ) - headerHeight),
                            behavior: 'smooth',
                        });
                        if (count > 2) {
                            cleanUp();
                            resolve(
                                {
                                    el: to.hash,
                                    top: headerHeight,
                                    behavior: 'smooth',
                                }
                            )
                        }
                    } else {
                        cleanUp();
                        resolve();
                    }
                }, 500)
                let scroll = (event) => {
                    cleanUp();
                    resolve();
                }
                cleanUp = () => {
                    clearInterval(interv);
                    removeEventListener("wheel", scroll);
                }
                addEventListener("wheel", scroll);
            });
        }

        if (to?.path !== from?.path && savedPosition) {
            if (from?.meta?.name === 'pds') {
                return new Promise((resolve) => {
                    let count = 0;
                    let cleanUp;
                    let interv = setInterval(() => {
                        let targ = document.getElementsByClassName('article-list');
                        if (targ[0]?.children?.length) {
                            count++;
                            window?.scrollTo(savedPosition);
                            if (count > 2) {
                                cleanUp();
                                resolve(savedPosition);
                            }
                        } else {
                            cleanUp();
                            resolve();
                        }
                    }, 500)
                    let scroll = (event) => {
                        cleanUp();
                        resolve();
                    }
                    cleanUp = () => {
                        clearInterval(interv);
                        removeEventListener("wheel", scroll);
                    }
                    addEventListener("wheel", scroll);
                });
            }
        }

        if (to?.path !== from?.path) {
            return new Promise((resolve) => {
                onNuxtReady(() => {
                    window?.scrollTo({ top: 0, left: 0 });
                    resolve({ top: 0, left: 0 });
                })
            });
        }
    }
}