<template>
  <header id="1" class="header z-20 relative lg:mb-[3.75rem]">
    <div class="lg:pt-24 text-sm flex lg:block px-4 lg:px-0">
      <div id="2" class="sticky-header grow">
        <div
          class="flex flex-col lg:flex-row lg:flex-wrap lg:container lg:mx-auto lg:items-center lg:h-full lg:justify-between gap-4">
          <NavigationStoreLogo class="py-3" />
          <div class="lg:order-last relative items-center lg:grow flex gap-4">
            <div class="mobile-menu-trigger grow lg:hidden">
              <button class="block border-0 bg-transparent lg:px-2 text-brand_primary lg:hidden" type="button"
                title="Menue" @click="
                  showSuperMenu = !showSuperMenu;
                showMenu = false;
                openDropdown = '0';
                openSubDropdown = '0';
                ">
                <span class="[&>svg]:w-6">
                  <BasicIcon :name="'menu'" :iconclass="'w-6 h-6'" />
                </span>
              </button>
            </div>

            <div v-if="($device.isMobile || $device.isTablet) && !$device.isLighthouse">
              <div class="flex items-center gap-4">
                <div class="relative">
                  <div class="lg:border-r lg:border-r-secondary-dark flex gap-3 lg:px-4 lg:py-2 mb-1 lg:my-2 hover:text-primary cursor-pointer items-center">
                    <NuxtLink :to="'/shop/wishlist'">
                      <BasicIcon class="w-6 h-6 text-primary cursor-pointer align-bottom" :name="'heart-shape'" />
                      <template v-if="useFeatureState('ES-5404-Counter')?.value == true">{{ wishListCounter }}</template>
                    </NuxtLink>
                  </div>
                </div>
                <div class="relative">
                  <div
                    class="UserAccountButton lg:border-r lg:border-r-secondary-dark flex gap-3 lg:px-4 lg:py-2 lg:my-2 hover:text-primary cursor-pointer items-center"
                    :class="showUserAccountDetails && !($device.isMobile || $device.isTablet)
                      ? 'flyout-indicator'
                      : ''
                      " @click="toggleUserAccountPopUp">
                    <NuxtLink v-if="$device.isMobile || $device.isTablet" :to="'/mein-expert/uebersicht'"
                      title="Mein Konto" :external="true">
                      <BasicIcon class="w-6 h-6 text-primary" :name="'circle-user-solid_02'" />
                    </NuxtLink>
                    <template v-else>
                      <BasicIcon class="w-6 h-6 text-primary" :name="'circle-user-solid_02'" />
                    </template>
                  </div>
                  <FlyOutsMyAccountFlyout v-if="showUserAccountDetails"
                    @changeFlyOutState="changeUserAccountFlyOutState($event)" :userAccount="userAccount" />
                </div>
                <div class="lg:relative">
                  <div class="LocalStoreButton flex gap-2 lg:px-4 hover:text-primary cursor-pointer items-center"
                    @click="toggleLocalStorePopUp" v-if="!showLocalStoreDetails">
                    <div class="grow text-base whitespace-nowrap">
                      <BasicIcon :name="'location-dot-solid'" class="w-4 inline-block text-primary" />
                      <div class="hidden lg:inline-block">Mein Fachmarkt</div>
                    </div>
                  </div>
                  <div
                    class="LocalStoreButton flex gap-2 lg:px-4 hover:text-primary cursor-pointer items-center flyout-indicator"
                    v-if="showLocalStoreDetails">
                    <div class="grow text-base whitespace-nowrap">
                      <BasicIcon :name="'location-dot-solid'" class="w-4 inline-block text-primary" />
                      <div class="hidden lg:inline-block">Mein Fachmarkt</div>
                    </div>
                  </div>

                  <div v-if="showLocalStoreDetails">
                    <FlyOutsLocalStoreFinderFlyout @changeFlyOutState="toggleLocalStorePopUp($event)" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="relative lg:btn-primary lg:order-last lg:text-primary-contrast text-primary cursor-pointer lg:flex gap-4 p-0 lg:p-4 font-normal lg:items-center"
              v-if="!showShoppingCartDetails" @click="toggleShoppingCartPopUp">
              <BasicIcon class="w-5 h-5 lg:w-4 lg:h-4 align-text-bottom" :name="'cart-shopping-solid'" />
              <div class="hidden lg:block">Warenkorb</div>
              <div
                class="shoppingcart-counter text-primary-contrast absolute text-xs lg:text-base bottom-full lg:bottom-auto lg:left-auto bg-black lg:bg-primary-dark lg:relative rounded-full text-center block">
                {{ (shoppingCartCount + reservationCartCount) || 0 }}
              </div>
            </div>
            <div
              class="relative lg:btn-primary lg:order-last lg:text-primary-contrast text-primary cursor-pointer lg:flex gap-4 p-0 lg:p-4 font-normal lg:items-center"
              v-if="showShoppingCartDetails">
              <BasicIcon class="w-5 h-5 lg:w-4 lg:h-4 align-text-bottom" :name="'cart-shopping-solid'" />
              <div class="hidden lg:block">Warenkorb</div>
              <div
                class="shoppingcart-counter text-primary-contrast absolute text-xs lg:text-base bottom-full lg:bottom-auto lg:left-auto bg-black lg:bg-primary-dark lg:relative rounded-full text-center block">
                {{ (shoppingCartCount + reservationCartCount) || 0 }}
              </div>
            </div>
            <BasicBtErrorBoundary>
              <FlyOutsShoppingCartFlyout v-if="showShoppingCartDetails"
                @changeFlyOutState="changeShoppingCartFlyOutState($event)" :shoppingCart="shoppingCart" />
            </BasicBtErrorBoundary>
            <template v-if="!($device.isMobile || $device.isTablet) && !$device.isLighthouse">
              <LayoutHeadSearchBox />
            </template>
          </div>
          <template v-if="($device.isMobile || $device.isTablet) && !$device.isLighthouse">
            <LayoutHeadSearchBox />
          </template>
        </div>
      </div>
    </div>
    <div class="lg:container lg:mx-auto lg:flex lg:justify-between">
      <NavigationSuperMenu @showmegamenu="showMenu = !showMenu" :supercategorytree="megaTree"
        :showSuperMenu="showSuperMenu" :openSuperDropdown="false" :showdesktopdropdownicon="false" :basedir="baseDir"
        :external="true">
        <template #megamenu>
          <div class="shop-nav left-0 right-0 absolute">
            <NavigationMegaMenuComponent v-if="navigationTree != undefined && !$device.isLighthouse"
              :tree="navigationTree" :class="'megamenu-container lg:container lg:mx-auto z-20'" :navbarnavclass="'w-full'"
              :navlinkdropdownclass="'py-4 lg:py-5 px-2'" :limit="4" :basedir="'' + baseDir" :external="false"
              :moretitle="'mehr'" :morenavitemclass="'more'" :useinternburgericon="false"
              :dropdowncontainerclass="'left-0 right-0 mt-0 lg:top-7 group-hover:translate-y-8 p-4 z-10'"
              :showMenu="showMenu" :openDropdown="openDropdown" :openSubDropdown="openSubDropdown" :multiplebanner="false"
              :showdesktopdropdownicon="false">
              <template #menuBannerContent v-if="!dotwPending && dotw?.type == 'default' && dotw?.url !== undefined">
                <aside
                  class="menue-banner text-paragraph p-4 bg-gradient-to-b from-[#ef7203] via-[#f7a501] to-[#ef7203]">
                  <NuxtLink :to="dotw?.url" :external="!dotw?.url?.includes('.html')" :title="dotw?.name">
                    <NuxtImg class="" :alt="dotw?.name" :src="dotw?.imageSource" preset="preview" />
                    <div class="text-base py-2">{{ dotw?.name }}</div>
                    <div class="text-lg">{{ dotw?.description }}</div>
                  </NuxtLink>
                </aside>
              </template>
            </NavigationMegaMenuComponent>
          </div>
        </template>
      </NavigationSuperMenu>
      <div class="flex items-center" v-if="!($device.isMobile || $device.isTablet)">
        <div class="relative">
          <div
            class="lg:border-r lg:border-r-secondary-dark flex gap-3 py-4 px-2 lg:px-4 lg:py-2 lg:my-2 hover:text-primary cursor-pointer items-center">
            <NuxtLink :to="'/shop/wishlist'">
              <BasicIcon class="w-6 h-6 text-primary cursor-pointer align-bottom" :name="'heart-shape'" />
              <sub v-if="useFeatureState('ES-5404-Counter')?.value == true">{{ wishListCounter }}</sub>
            </NuxtLink>
          </div>
        </div>
        <div class="relative">
          <div
            class="UserAccountButton whitespace-nowrap lg:border-r lg:border-r-secondary-dark flex gap-3 py-4 px-2 lg:px-4 lg:py-2 lg:my-2 hover:text-primary cursor-pointer items-center"
            @click="toggleUserAccountPopUp" v-if="!showUserAccountDetails">
            <NuxtLink v-if="$device.isMobile || $device.isTablet" :to="'/mein-expert/uebersicht'" :external="true"
              title="Mein Konto">
              <BasicIcon class="w-6 h-6 text-primary" :name="'circle-user-solid_02'" />
            </NuxtLink>
            <template v-else>
              <BasicIcon class="w-6 h-6 text-primary" :name="'circle-user-solid_02'" />
            </template>
            <div class="grow text-base hidden lg:block">Mein Konto</div>
          </div>
          <div
            class="UserAccountButton flyout-indicator whitespace-nowrap lg:border-r lg:border-r-secondary-dark flex gap-3 py-4 px-2 lg:px-4 lg:py-2 lg:my-2 hover:text-primary cursor-pointer items-center"
            v-if="showUserAccountDetails">
            <NuxtLink v-if="$device.isMobile || $device.isTablet" :to="'/mein-expert/uebersicht'" :external="true"
              title="Mein Konto">
              <BasicIcon class="w-6 h-6 text-primary" :name="'circle-user-solid_02'" />
            </NuxtLink>
            <template v-else>
              <BasicIcon class="w-6 h-6 text-primary" :name="'circle-user-solid_02'" />
            </template>
            <div class="grow text-base hidden lg:block">Mein Konto</div>
          </div>
          <FlyOutsMyAccountFlyout v-if="showUserAccountDetails" @changeFlyOutState="changeUserAccountFlyOutState($event)"
            :userAccount="userAccount" />
        </div>

        <div class="relative">
          <div class="LocalStoreButton flex gap-2 lg:py-4 lg:px-4 hover:text-primary cursor-pointer items-center"
            @click="toggleLocalStorePopUp" v-if="!showLocalStoreDetails">
            <div class="grow text-base whitespace-nowrap">
              <BasicIcon :name="'location-dot-solid'" class="w-4 lg:mr-2 lg:align-text-top inline-block text-primary" />
              <div class="hidden lg:inline-block">Mein Fachmarkt</div>
            </div>
          </div>
          <div
            class="LocalStoreButton flex gap-2 lg:py-4 lg:px-4 hover:text-primary cursor-pointer items-center flyout-indicator"
            v-if="showLocalStoreDetails">
            <div class="grow text-base whitespace-nowrap">
              <BasicIcon :name="'location-dot-solid'" class="w-4 lg:mr-2 lg:align-text-top inline-block text-primary" />
              <div class="hidden lg:inline-block">Mein Fachmarkt</div>
            </div>
          </div>
          <FlyOutsLocalStoreFinderFlyout v-if="showLocalStoreDetails"
            @changeFlyOutState="toggleLocalStorePopUp($event)" />
        </div>
      </div>
    </div>
  </header>
</template>
<script setup lang="ts">
import { getDealOfTheWeek, recursiveSort } from "~/composables/ExpApi/expCache";
import { useSuperMenuState } from "~/composables/states";
import { useMobileMenuState } from "~/composables/states";
import {
  useShoppingCartItemsCount,
  useReservationCartItemsCount,
} from "~/composables/states";

const props = defineProps({
  baseDir: {
    default: () => {
      return inject(ProvideNames.BASE_DIR);
    },
  },
});

const shoppingCartCount = useShoppingCartItemsCount();
const reservationCartCount = useReservationCartItemsCount();

const wishListCounter = useWishListItemCount();
const route = useRoute();

const userAccount: Object = {};
const shoppingCart: Object = {};

const showSuperMenu = useSuperMenuState();
const showMenu = useMobileMenuState();
const openDropdown = ref("0");
const openSubDropdown = ref("0");

watch(
  route,
  (value) => {
    showSuperMenu.value = false;
    showMenu.value = false;
  },
  {
    deep: true,
    immediate: true,
  }
);

const showShoppingCartDetails = ref(false);
function changeShoppingCartFlyOutState(e) {
  if (!showShoppingCartDetails.value) {
    showShoppingCartDetails.value = true;
  } else {
    showShoppingCartDetails.value = false;
  }
}

const { data: navigationTree } = await useFetch(useRuntimeConfig().public.apiUrl + "/api/navigation", {
  key: 'navigationTree-MAIN',
  query: {
    storeId: computed(() => useCurrentStore()?.value?.id),
    tag: "MAIN",
  },
  transform(data) {
    let result = data;
    if (result && Array.isArray(result) && result.length > 0) {
      let res = result.filter((nav) => nav.primaryTree && nav.descendants?.length > 0);
      res?.forEach((cat) => recursiveSort(cat));
      res.sort((a, b) => b.position - a.position);
      return res;
    }
    return result;
  },
});

const { data: megaTree } = await useFetch(useRuntimeConfig().public.apiUrl + "/api/navigation", {
  key: 'navigationTree-SUPER',
  query: {
    storeId: computed(() => useCurrentStore()?.value?.id),
    tag: "SUPER",
  },
  transform(data) {
    let result = data;
    if (result && Array.isArray(result) && result.length > 0) {
      let res = result;
      res?.forEach((cat) => recursiveSort(cat));
      res.sort((a, b) => b.position - a.position);
      return res.reverse();
    }
    return result;
  },
});

const { pending: dotwPending, data: dotw } = await getDealOfTheWeek();

function toggleShoppingCartPopUp() {
  showShoppingCartDetails.value = !showShoppingCartDetails.value;
}

const showUserAccountDetails = ref(false);

function changeUserAccountFlyOutState(e) {
  if (!showUserAccountDetails.value) {
    showUserAccountDetails.value = true;
  } else {
    showUserAccountDetails.value = false;
  }
}

function toggleUserAccountPopUp() {
  showUserAccountDetails.value = !showUserAccountDetails.value;
}

const showLocalStoreDetails = ref(false);

function toggleLocalStorePopUp() {
  if (!showLocalStoreDetails.value) {
    showLocalStoreDetails.value = true;
  } else {
    showLocalStoreDetails.value = false;
  }
}

onNuxtReady(() => {
  try {
    window.addEventListener("CookiebotOnConsentReady", function (e) {
      var prefs = {
        REQUIRED: Cookiebot.consent.necessary,
        STATISTICS: Cookiebot.consent.statistics,
        MARKETING: Cookiebot.consent.marketing,
        PRODUCT_RATINGS: Cookiebot.consent.preferences,
      };
      refreshCookie('CookieConsent');
      for (let k in prefs) {
        useState("cookie" + k).value = prefs[k];
      }
    });
  } catch (error) {
    console.warn("error checking cookie permissions.", error);
  }
});
</script>
<style>
.store-logo-dark {
  filter: hue-rotate(180deg);
}

.store-logo-dark-2 {
  filter: invert(1);
}
</style>
