<template>
  <template v-if="!pending && medium?.originalData">
    <NuxtImg
      provider="cloudflare"
      :src="'/' + medium?.originalData"
      :alt="altText"
      :title="title"
      :densities="densities"
      :width="width"
      :preset="preset"
      :loading="lazy ? 'lazy' : undefined"
      :draggable="!dragRestriction"
    />
  </template>
  <template v-else-if="pending && imagePlaceHolder != undefined">
    <NuxtImg
      provider="cloudflare"
      :src="imagePlaceHolder"
      :alt="altText"
      :title="title"
      :densities="densities"
      :width="width"
      :preset="preset"
      :loading="lazy ? 'lazy' : undefined"
      :draggable="!dragRestriction"
    />
  </template>
</template>
<script setup lang="ts">
import { inject } from 'vue';
import { getMedium } from "~/composables/ExpApi/expCache";

const props = defineProps({
  mediumId: {
    type: String,
    required: true,
  },
  dragRestriction: {
    type: Boolean,
    default: true,
  },
  width: {
    type: Number,
  },
  preset: {
    type: String,
  },
  lazy: {
    type: Boolean,
    default: true,
  },
  debug: {
    type: Boolean,
    default: false,
  },
  imagePlaceHolderSrc: {
    type: String
  },
  densities: {
    type: String,
    default: 'x1',
  }
});

const imagePlaceHolder = props?.imagePlaceHolderSrc ? props?.imagePlaceHolderSrc : inject("ImagePlaceHolder");

const { pending, data: medium } = await getMedium(props?.mediumId);
if (medium?.originalData !== undefined) {
  pending.value = false;
}

const altText = computed(() => {
  return medium?.value?.alt ?? medium?.value?.title ?? '';
});

const title = computed(() => {
  return medium?.value?.title ?? medium?.value?.alt ?? '';
});
</script>
