/**
 * Takes an Array of Articles and returns A shoppingcart with these Articles, since then the Article is connected to it's Promotions
 *
 * @param {Array<string>} articles
 * @param {string} storeId
 */
export const getPromotionShoppingCart: Function = async (articles: Array<string>, storeId: string, activePromotions: any) => {
	const context: string = "de_DE|SHOP";
	const { data: promotionShoppingCart } = await useFetch("/api/neo/shoppingCart-service/calculateStickyDiscounts", {
		method: "POST",
		headers: {
			"bt-use-user-auth": "true",
			"csrf-token": useCurrentUserState().value?.csrfToken,
		},
		body: {
			articles: articles,
			context: context,
			store: storeId,
		},
		credentials: "include",
		transform(data) {
			const lastAdded = data["lastAdded"];
			const article: ArticlePromotions = {
				currentPrice: lastAdded["price"]["gross"],
				directDiscount: 0,
				checkoutPrice: lastAdded["price"]["gross"],
				cashbackDiscount: 0,
				cashbackTitle: "",
				afterCashbackPrice: lastAdded["price"]["gross"],
			};
			let cashbackPriority = 0;
			for (let promotion in lastAdded["promotions"]) {
				const split = promotion?.split(":");
				const articlePromotion = lastAdded["promotions"][promotion];
				const promo = activePromotions?.value?.find(
					(prom) => prom?.id == split[0] && (split[1] == undefined || prom?.orderModification?.find((mod) => mod.id == split[1]) != undefined)
				);
				if (articlePromotion?.gross < 0 && promo?.showInCheckout) {
					const modification = promo?.orderModification?.find((mod) => mod.id == split[1]);
					if (modification.type === "STICKY") {
						if (article.cashbackDiscount === 0 || (article.cashbackDiscount !== 0 && cashbackPriority < modification.priority)) {
							article.cashbackDiscount = articlePromotion?.gross;
							article.cashbackTitle = promo?.title;
							cashbackPriority = modification.priority;
						}
					} else {
						article.directDiscount += articlePromotion?.gross;
					}
				}
			}
			article.checkoutPrice = article.currentPrice + article.directDiscount;
			article.afterCashbackPrice = article.checkoutPrice + article.cashbackDiscount;
			return article;
		},
	});
	return promotionShoppingCart;
};

export interface ArticlePromotions {
	currentPrice: number;
	directDiscount: number;
	checkoutPrice: number;
	cashbackDiscount: number;
	cashbackTitle: string;
	afterCashbackPrice: number;
}
