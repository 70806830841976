import version from '~/package.json'

export default defineNuxtPlugin((_nuxtApp) => {
    const projectVersion = version?.version
    const nuxtVersion = version.devDependencies.nuxt

    if (useRuntimeConfig()?.public?.econda?.enabled.toString() === 'true' && !useDevice()?.isCrawler) {
        console.info('Econda enabled');
        if (window) {
            window.econda = { onReady: [], ready: function (f) { this.onReady.push(f); } };
            window.emos3 = window.emos3 || {
                defaults: {
                    siteid: useCurrentStoreCompanyGroup(),
                    countryid: useCurrentStoreId(),
                    herkunftsland: 'Nuxt',
                    pageId: nuxtVersion + '_' + projectVersion + '_' + useRoute()?.name
                },
                stored: [],
                send: function (p) {
                    this.stored.push(p);
                },
                checkPoint: function (checkpoint, subpoint) {
                    // console.debug('called content for ', checkpoint)
                    this.send({
                        langid: 'userbotscore=' + useABConfigCookie()?.value?.context?.userbotscore,
                        siteid: useCurrentStoreCompanyGroup(),
                        countryid: useCurrentStoreId(),
                        url: [[useRoute()?.fullPath]],
                        content: getDefaultContent(toValue(checkpoint), toValue(subpoint)),
                        orderProcess: getDefaultOrderProcess(toValue(checkpoint), toValue(subpoint)),
                    });
                }
            };
            // console.debug('found ', window.emos3)
            // watchEffect(() => {
            const marketingCookie = getCookiePermissions("MARKETING");
            if (marketingCookie?.value == true) {
                window.emos3.PRIV_MODE = 3
                // window.emos3.send({ privacy_mode: 3 });
            } else {
                window.emos3.PRIV_MODE = 2
                // window.emos3.send({ privacy_mode: 2 });
            }
            // })

            useHead({
                script: [{
                    src: "/static/resources/private/javascript/econda/emos3.js",
                    type: "text/javascript",
                    async: true,
                }]
            })

            return {
                provide: {
                    emos3: window.emos3,
                },
            };
        }
    } else {
        return {
            provide: {
                emos3: {
                    defaults: {},
                    stored: [],
                    send: function () {},
                    checkPoint: function () {}
                },
            },
        };
    }

    function getDefaultContent(checkpointP, subpoint) {
        let checkpoint = checkpointP || useRoute()?.query?.step
        let content;
        if (useRoute()?.path?.includes('checkout') && useRoute()?.fullPath?.toLowerCase().includes("reservation")) {
            content = (useRoute()?.query?.changer == 'true' || useState('isChanger')?.value == 'true') ? 'reservation-checkout-changer' : 'reservation-checkout';
            if (checkpoint == 1 || (useRoute()?.path?.endsWith('reservation') && checkpoint == undefined)) {
                content += '/1_Warenkorb';
            } else if (checkpoint == 2) {
                content += '/2_Adresse';
            } else if (checkpoint == 3) {
                content += '/3_Reservierungsübersicht';
            } else if (useRoute()?.path?.includes('confirmation')) {
                content += '/4_Reservierungsbestätigung';
            }
        } else if (useRoute()?.path?.includes('checkout')) {
            content = 'Checkout';
            if (checkpoint == 1 || (useRoute()?.path?.endsWith('checkout') && checkpoint == undefined)) {
                content += '/Warenkorb';
            } else if (checkpoint == 2) {
                if(subpoint == 0) {
                    content += '/Login';
                } else {
                    content += '/Adresse';
                }
            } else if (checkpoint == 3) {
                content += '/Lieferart';
            } else if (checkpoint == 4) {
                content += '/Payment';
            } else if (checkpoint == 5) {
                content += '/Übersicht';
            } else if (useRoute()?.path?.includes('confirmation')) {
                content += '/Bestellbestätigung';
            }
        } else {
            content = useRoute()?.path
        }
        return content;
    }

    function getDefaultOrderProcess(checkpointP, subpoint) {
        let checkpoint = checkpointP || useRoute()?.query?.step
        let orderProcess;
        if (useRoute()?.path?.includes('checkout') && !useRoute()?.fullPath?.toLowerCase().includes("reservation")) {
            if (checkpoint == 1 || (useRoute()?.path?.endsWith('checkout') && checkpoint == undefined)) {
                orderProcess = '1_Cart';
            } else if (checkpoint == 2) {
                if(subpoint == 0) {
                    orderProcess = '2_Login';
                } else {
                    orderProcess = '2_PersonalData';
                }
            } else if (checkpoint == 3) {
                orderProcess = '3_Delivery';
            } else if (checkpoint == 4) {
                orderProcess = '4_Payment';
            } else if (checkpoint == 5) {
                orderProcess = '5_Confirmation';
            } else if (useRoute()?.path?.includes('confirmation')) {
                orderProcess = '6_purchase';
            }
        } else {
            orderProcess = undefined;
        }
        return orderProcess;
    }
})
