<template>
    <div :class="checkoutClass ? 'text-success font-bold lg:order-3 col-span-2 lg:text-xs' : 'text-xs text-paragraph-light mt-2'" v-if="typeof price?.minOnlineDelivery == 'number' && price?.minOnlineDelivery > 0
        && typeof price?.maxOnlineDelivery == 'number' && price?.maxOnlineDelivery > 0">
        <BasicIcon :name="'truck-solid'" class="w-3 mr-1"></BasicIcon>
        <template v-if="price?.maxOnlineDelivery == 1"><span :class="checkoutClass ? '' : 'text-sm font-bold text-black'">Lieferung am</span> {{ delivery.minDay }}.{{ delivery.minMonth }}.{{
        delivery.minYear }}</template>
        <template v-else-if="price?.maxOnlineDelivery == price?.minOnlineDelivery"><span :class="checkoutClass ? '' : 'text-sm font-bold text-black'">Lieferung am</span> {{ delivery.maxDay }}.{{
        delivery.maxMonth }}.{{ delivery.maxYear }}</template>
        <template v-else><span :class="checkoutClass ? '' : 'text-sm font-bold text-black'">Lieferung </span> {{ delivery.minDay }}.{{ delivery.minMonth }}.{{ delivery.minYear }} -
            {{ delivery.maxDay }}.{{ delivery.maxMonth }}.{{ delivery.maxYear }}</template>
    </div>
</template>
<script setup lang="ts">
import { Price } from '~/models/ExpPrice/exp_Price';
import { changeNumberToDate } from '~/composables/deliveryCalculater'
const props = defineProps({
    price: {
        type: Object,
        required: true,
    },
    checkoutClass: {
        type: Boolean,
        default: false
    }
})
const delivery = changeNumberToDate(props?.price)
</script>