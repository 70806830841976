<template>
  <div>
    <Meta v-if="!$device.isDesktop" name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0" />
    <LayoutColorModePicker
      v-if="useFeatureState('cfg-blackweek-header')?.value === true && useCurrentStore()?.value?.companyGroupId != '1906674'"
      defaultColorMode="blackweek" />
    <LayoutColorModePicker v-else-if="useCurrentStore()?.value?.companyGroupId == '1906674'"
      defaultColorMode="blue-schimmel-cheese" />
    <LayoutColorModePicker v-else defaultColorMode="default" />
    <LayoutHead />
    <ContentBlackWeekBanner v-if="useFeatureState('cfg-blackweek-header')?.value === true" />
    <main>
      <BasicBtErrorBoundary>
        <slot />
      </BasicBtErrorBoundary>
    </main>
    <LayoutFooter />
  </div>
</template>
<script setup lang="ts">
</script>
