<template>
  <div class="energyEfficiency cursor-pointer">
    <BasicBtErrorBoundary> 
      <div class="cheeseAndOnions" v-if="!energyMap.get('newScale')">
        <div
          class="letterContainer"
          @click.prevent="enableEnergyLabel = true"
          v-if="energyMap.get('ColorPalette') !== undefined"
          :style="{ color: labelColorStyle.color, fontSize: `${labelTextSize}px` }">
          <span class="letter" :style="{ backgroundColor: labelColorStyle.color }">{{ splitLetterAndPlus().letter }}<sup>{{ splitLetterAndPlus().pluses }}</sup></span>
        </div>
        <div class="letterRange">
          {{ energyMap.get("LetterRange") }}
        </div>
      </div>
      <div v-else class="saltAndVinegar">
        <NuxtImg
          densities="x1"
          @click.prevent="enableEnergyLabel = true"
          v-if="energyMap?.get('Letter')"
          :alt="'Energy Effizienz ' + energyMap.get('Letter') + ' (' + energyMap.get('LetterRange') + ')'"
          :style="{ maxHeight: `${labelSvgSize}px`, minHeight: `${labelSvgSize}px` }"
          :src="'/static/images/icons/efficiencyRating/range/energielabel_' + energyMap.get('Letter')?.toString()?.toLowerCase() + '.svg'"
        />
      </div>
      <div class="listDocuments" v-if="showDataFile && energyEfficiency?.get('DataFileId') != undefined && ((energyMap.get('newScale') && energyMap?.get('Letter')) || (!energyMap.get('newScale') && energyMap.get('ColorPalette') !== undefined))">
        <div class='entry' @click.prevent='openInNewTab()'>
          Produktdatenblatt
        </div>
      </div>
      <Teleport to="body">
        <div v-if="enableEnergyLabel">
          <Modal @close-modal="enableEnergyLabel = false">
            <template #icon>
              <BasicIcon :name="'xmark-solid'" class="w-4 close-img"></BasicIcon>
            </template>
            <div class="p-4" v-if="getFullImage()?.originalData">
              <img :alt="'Energieeffizienz: ' + energyMap.get('Letter')?.toString()" class="mx-auto lg:max-h-[80vh]"
                :src="'https://cdn.expert.de/' + getFullImage()?.originalData" />
            </div>
          </Modal>
        </div>
      </Teleport>
    </BasicBtErrorBoundary>
  </div>
</template>

<script setup lang="ts">
import { getMedium } from "~/composables/ExpApi/expCache";

const props = defineProps({
  article: {
    type: Object,
    required: true,
  },
  showDataFile: {
    type: Boolean,
    default: true
  },
  labelSvgSize: {
    type: Number,
    default: 34
  },
  labelTextSize: {
    type: Number,
    default: 48
  },
});

const enableEnergyLabel = ref(false);
const energyMap = ref(new Map());
const energyEfficiency = new Map();

const technicalDataIdMapping: Record<string, string> = {
  '59ba44f5a7b11b000152d48a': 'DataFileId',
  '59ba44f5a7b11b000152d4d2': 'ImageId',
  '59ba44f5a7b11b000152d466': 'ColorPalette',
  '60501133ef918200012ab3f8': 'LetterRange',
  '59ba44f5a7b11b000152d1c3': 'Letter',
  '59ba44f5a7b11b000152d065': 'Letter',
}

const splitLetterAndPlus = (): { letter: string; pluses: string } => {
  const letter = energyMap?.value?.get("Letter")?.charAt(0) ?? '';
  const pluses = energyMap?.value?.get("Letter")?.substring(1) ?? '';

  return {
    letter,
    pluses
  };
}

const parseTechnicalDataValue = (key: string, value: string) => {
  let parsedValue = value;
  if (technicalDataIdMapping[key] === 'ColorPalette') {
    parsedValue = parsedValue.substring(1).replace('x', '#');
  }

  return parsedValue;
};

for (const technicalData of props.article.technicalData) {
  if (technicalData.value.value && technicalData.value.value !== '') {
    const parsedValue = parseTechnicalDataValue(technicalData.key, technicalData.value.value)
    energyEfficiency.set(technicalDataIdMapping[technicalData.key], parsedValue);
  }
}

if (energyEfficiency.get("Letter")?.toString().includes("+") || energyEfficiency.get("LetterRange")?.toString().includes("+")) {
  energyEfficiency.set("newScale", false);
} else {
  energyEfficiency.set("newScale", true);
}

function getFullImage() {
  if (!energyMap.value.get('FullImage')) {
    const { data: result } = getMedium(energyEfficiency.get("ImageId"));
    energyEfficiency.set("FullImage", result.value);
  }
  return energyMap.value.get('FullImage');
}

async function getDataFile() {
  if (!energyMap.value.get('DataFile')) {
    const { data: result } = await useFetch(useRuntimeConfig().public.apiUrl + "/api/medium/" + energyEfficiency.get("DataFileId"), {
      key: "medium/id=" + energyEfficiency.get("DataFileId"),
    });
    energyEfficiency.set("DataFile", unref(result.value));
    energyMap.value.set("DataFile", unref(result.value));
  }
  return energyMap.value.get('DataFile');
}

async function openInNewTab () {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: 'https://cdn.expert.de/' + (await getDataFile())?.originalData,
  }).click()
}

energyMap.value = energyEfficiency;

const labelColorStyle = {
  backgroundColor: energyMap.value.get('ColorPalette'),
  color: energyMap.value.get('ColorPalette'),
}
</script>