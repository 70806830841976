<template>
  <footer>
    <BasicScrollToTop></BasicScrollToTop>
    <div class="text-sm" v-if="!$device.isLighthouse">
      <div class="hidden lg:block bg-secondary-light solid border-t border-secondary">
        <div class="lg:container lg:mx-auto py-5 flex text-paragraph font-bold justify-between text-center">
          <div class="text-left flex items-center gap-2">
            <BasicIcon class="w-4" :name="'address-card-regular'" /> Experten-Beratung
          </div>
          <div class="flex items-center gap-2">
            <BasicIcon class="w-4" :name="'wrench-solid'" /> Reparatur-Service
          </div>
          <div class="flex items-center gap-2">
            <BasicIcon class="w-4" :name="'gear-solid'" /> Montage-Service
          </div>
          <div class="flex items-center gap-2">
            <BasicIcon class="w-3" :name="'euro-sign-solid'" /> Finanzierungs-Service
          </div>
          <div class="flex items-center gap-2">
            <BasicIcon class="w-4" :name="'rotate-regular'" /> Altgeräte-Entsorgung
          </div>
          <div class="text-right flex items-center gap-2">
            <BasicIcon class="w-4" :name="'truck-solid'" /> Liefer-/Abholservice
          </div>
        </div>
      </div>
      <div class="bg-secondary p-4">
        <div class="lg:container lg:mx-auto lg:py-5">
          <div class="grid grid-cols-12 leading-8">
            <div class="col-span-7 hidden lg:block font-bold pb-4">expert Vorteile</div>
            <div class="col-span-12 lg:col-span-5 font-bold pb-4">expert Newsletter</div>
            <div class="col-span-7 hidden lg:block text-paragraph-light">
              <div class="gap-4 grid grid-cols-2 ">
                <ul>
                  <li class="flex items-center gap-2">
                    <BasicIcon class="w-4 text-primary" :name="'check-regular'" /> Bei expert bezahlen Sie flexibel und
                    sicher
                  </li>
                  <li class="flex items-center gap-2">
                    <BasicIcon class="w-4 text-primary" :name="'check-regular'" /> Beste Beratung und Top-Services
                  </li>
                  <li class="flex items-center gap-2">
                    <BasicIcon class="w-4 text-primary" :name="'check-regular'" /> Deutschlandweit über 400 Standorte
                  </li>
                  <li class="flex items-center gap-2">
                    <BasicIcon class="w-4 text-primary" :name="'check-regular'" /> Bequeme Lieferung nach Hause
                  </li>
                </ul>
                <ul>
                  <li class="flex items-center gap-2">
                    <BasicIcon class="w-4 text-primary" :name="'check-regular'" /> Direktabholung in Ihrem Fachmarkt
                  </li>
                  <li class="flex items-center gap-2">
                    <BasicIcon class="w-4 text-primary" :name="'check-regular'" /> Verfügbarkeitsbenachrichtigung
                  </li>
                  <li class="flex items-center gap-2">
                    <BasicIcon class="w-4 text-primary" :name="'check-regular'" /> Aktionen & Gewinnspiele
                  </li>
                  <li class="flex items-center gap-2">
                    <BasicIcon class="w-4 text-primary" :name="'check-regular'" /> 30 Tage Käuferschutz durch Trusted
                    Shops
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-span-12 lg:col-span-5">
              <a href="https://www.expert.de/#df5a532d-3a13-48df-b3a4-dba7b2b62fea|SLIDE_IN_DOWN"
                class="btn-primary w-full text-center max-w-xs mb-3 block font-bold">
                Jetzt registrieren
              </a>
              <div class="text-primary font-bold">Kostenlose Registrierung</div>
              <div class="leading-normal">
                Bleiben Sie stets über die aktuellsten Angebote und Techniktrends
                informiert.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12 py-5 px-4" v-if="$device.isMobile || $device.isTablet">
        <nav class="flex flex-wrap justify-center" v-if="!$device.isLighthouse && navigationTree">
          <template v-for="hardlink in cms_navigation" :key="hardlink.id">
            <template v-if="hardlink.descendants?.length > 0">
              <template v-for="link in hardlink.descendants" :key="link.id">
                <span class="first:hidden">|</span><a class="text-primary px-2" :href="link.url" target="_self"
                  :title="link.name">{{ link.name }}</a>
              </template>
            </template>
          </template>
        </nav>
      </div>
      <div class="lg:container lg:mx-auto lg:py-5">
        <div class="hidden lg:text-left lg:grid grid-cols-12 lg:pb-7 gap-12 font-bold">
          <div class="col-span-4">Folgen Sie uns</div>
          <div class="col-span-3">Sicher einkaufen bei expert</div>
          <div class="col-span-5">Wir akzeptieren im Onlineshop folgende Zahlarten</div>
        </div>
        <div class="lg:text-left lg:grid grid-cols-12 lg:pb-7 gap-12 items-center">
          <div class="col-span-5 order-1 lg:order-3 border-t border-b lg:border-0">
            <div class="lg:hidden text-center px-4 pt-4 font-bold">Wir akzeptieren im Onlineshop folgende Zahlarten</div>
            <div class="grid grid-cols-3 lg:flex lg:justify-between items-center gap-4 gap-y-6 p-4 lg:p-0">
              <div class="flex justify-center" v-for="entry in paymentprovider.service" :key="entry.image">
                <nuxt-img densities="x1" loading="lazy" :src="entry.image" provider="cloudflare" :title="entry.title"
                  :alt="entry.alt" style="min-height: 20px; max-height: 40px" />
              </div>
            </div>
          </div>
          <div class="hidden lg:block col-span-3 order-2">
            <nav class="flex items-center gap-4">
              <a :href="entry.href" v-for="entry in shoprating.service" :key="entry.image" :title="entry.title">
                <nuxt-img densities="x1" loading="lazy" class="h-11" provider="cloudflare" :src="entry.image"
                  :alt="entry.alt" />
              </a>
            </nav>
          </div>
          <div class="col-span-4 order-3 lg:order-1">
            <div class="lg:hidden text-center px-4 pt-4 font-bold">Folgen Sie uns</div>
            <nav class="flex justify-between lg:justify-start items-center gap-4 p-4 lg:p-0">
              <a v-for="entry in social_navigation" :href="entry.url" :key="entry.id" target="_blank" :title="entry.name">
                <img loading="lazy" :src="entry.imgSrc" :alt="entry.alt" class="min-h-[20px] max-h-10" />
              </a>
            </nav>
          </div>
        </div>
      </div>
      <template v-if="$device.isDesktop">
        <div class="container mx-auto pb-9">
          <FooterNavigationComponent v-if="!$device.isLighthouse" :tree="navigationTree" :limit="10"
            :basedir="baseDir" :external="false" :moretitle="'mehr'" :morenavitemclass="'more'"
            :class="'grid grid-cols-6 gap-8'" />
        </div>
      </template>
    </div>
    <div class="lg:bg-secondary footer-nav" v-if="$device.isDesktop">
      <div class="container mx-auto py-4 lg:py-5" v-if="!$device.isLighthouse">
        <nav class="cms-nav grid grid-cols-6 gap-8">
          <!--ul v-for="hardlink in hardlinks.categories" v-bind:key="hardlink.name">
            <li class="nav-item" v-text="hardlink.name"></li>
            <li class="nav-item" v-for="link in hardlink.links" v-bind:key="link.title">
              <a :href="link.href" v-text="link.title" />
            </li>
          </ul-->
          <ul v-for="hardlink in cms_navigation" :key="hardlink.id">
            <li class="nav-item" v-text="hardlink.name" />
            <template v-if="hardlink.descendants?.length > 0">
              <li class="nav-item" v-for="link in hardlink.descendants" :key="link.id">
                <NuxtLink class="nav-link" :to="link?.url" :external="true" :title="link.title">{{ link.name }}</NuxtLink>
              </li>
            </template>
          </ul>
        </nav>
      </div>
    </div>
    <div class="text-sm text-center lg:text-left bg-secondary-light p-4" v-if="!$device.isLighthouse">
      <div class="lg:container mx-auto py-4 lg:py-5">
        <!-- AUSKOMMENTIERT FÜR BW -->
        <!-- <p>
          <BasicIcon class="w-3 mr-2" :name="'phone-solid'" />
          <strong>Kundenhotline: 0203 - 80481842</strong>
          <br class="lg:hidden" />
          (erreichbar Mo-Sa von 09:00 – 20:00
          Uhr).
        </p>
        <br /> -->
        <p>
          Alle Preise in Euro und inkl. der gesetzlichen Mehrwertsteuer. ggf. zzgl.
          Versandkosten. <br />
          Lieferung innerhalb Deutschlands.
          <br /><br />
          Änderungen und Irrtümer vorbehalten. Abbildungen ähnlich. Nur solange der Vorrat
          reicht.
        </p>
        <br />
        <p><strong>© expert e-Commerce GmbH</strong></p>
        <p>&nbsp;</p>
        <span style="color: #f2f2f2;"><a style="color: #f2f2f2;" href="/shop/unsere-produkte/tv-audio?campaignID=" >weiter zur
            expert.de</a></span>
      </div>
    </div>
    <Modal v-if="enableNewsletterTeaserModal" class="sm" @close-modal="enableNewsletterTeaserModal = false">
      <div class="grid lg:grid-cols-2 p-4 newsletter-teaser"
        style="background-color: rgba(255,255, 255, 0.0); background-image: url(https://cdn.expert.de/7b/ac/1a/0186abeb16b055e07d3bddcb3e25880a98/NL_St%25C3%25B6rer_BG_100-0.png);background-repeat: no-repeat;background-position-x: left;background-position-y: top;background-size: cover;">
        <div class="flex flex-col gap-6">
          <div class="text-primary text-lg">expert Newsletter</div>
          <p>
            Nehmen Sie an unseren zahlreichen Gewinnspielen und Aktionen teil, bleiben Sie
            stets über aktuelle Angebote und Techniktrends informiert.
          </p>
          <div>
            <select class="inp">
              <option>Anrede</option>
              <option>Herr</option>
              <option>Frau</option>
              <option>Divers</option>
              <option>Firma</option>
              <option>Familie</option>
            </select>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <div>
              <input class="inp" type="text" placeholder="Vorname" name="firstname" />
            </div>
            <div>
              <input class="inp" type="text" placeholder="Nachname" name="lastname" />
            </div>
          </div>
          <div>
            <input class="inp" type="text" placeholder="E-Mail-Adresse*" name="email" />
          </div>
          <hr />
          <div>
            <input type="text" disabled placeholder="expert Store - PLZ City" name="store" class="inp" />
          </div>
          <hr />
          <div>
            <BasicCaptcha />
          </div>
          <button type="submit" class="btn-primary font-bold">
            Jetzt kostenlos registrieren
          </button>
        </div>
      </div>
    </Modal>
  </footer>
</template>
<script setup>
import { inject } from "vue";
import { getExpNavigationTree } from "~/composables/ExpApi/expCache";
import { ProvideNames } from "~/composables/statics";
// import { useCurrentStore } from "~/composables/states";
const props = defineProps({
  enableNewsletterTeaserModal: {
    type: Boolean,
    default: false,
  },
  baseDir: {
    default: () => {
      return inject(ProvideNames.BASE_DIR);
    },
  },
});


const { isLighthouse } = useDevice();
const { data: navigationTree } = isLighthouse ? [] : await getExpNavigationTree("FOOTER");
const { data: cms_navigation } = isLighthouse ? [] : await getExpNavigationTree("FOOTER_HARDLINKS");
const { data: social_navigation } = isLighthouse ? [] : await getExpNavigationTree("FOOTER_SOCIAL");


const storeRef = inject(ProvideNames.CURRENT_STORE_KEY);
const updateFunction = storeRef?.updateFunction || console.error;

const paymentprovider = {
  service: [
    {
      image: "/59/b1/e7/d5c2ef10b639c5fa5c7135def83b8ffd2d/paypal.png",
      alt: "PayPal",
      title: "PayPal",
    },
    {
      image: "/59/66/c1/5f9097f6f0a1870b1bc1aa65adbab43b50/AmericanExpress-New.png",
      alt: "AmericanExpress",
      title: "AmericanExpress",
    },
    {
      image: "/52/b5/f9/f9b6cfdc549077bf4ceaa072a07fe542fa/Visa.png",
      alt: "Visa",
      title: "Visa",
    },
    {
      image: "/6f/c6/7d/ceb8c0229fab5cb8a7f84b8015bc7260de/mastercard.png",
      alt: "mastercard",
      title: "mastercard",
    },
    {
      image: "/63/8d/a9/844f4b4d26f4c76fd92f68e6b8daac93c0/Klarna.png",
      alt: "Klarna",
      title: "Klarna",
    },
    {
      image: "/68/1a/97/f3eeb92221b695fb99a95694480b268159/Billie-logo.png",
      alt: "Billie",
      title: "Billie",
    },
  ],
};

const shoprating = {
  service: [
    {
      image: "/static/images/test/trustedShop.jpg",
      href:
        "https://www.trustedshops.de/bewertung/info_X6C1E9EE9E034D3276D30CB55A1778205.html",
      alt: "trusted shop",
      title: "trusted shops",
    },
    {
      image: "/98/43/45/4873302d7ac7b399e54afc07be2bd60d8f/s1_idealo-partner_90-0.png",
      href: "https://www.idealo.de/preisvergleich/Shop/286835.html",
      alt: "unsere Bewertungen bei idealo.de",
      title: "unsere Bewertungen bei idealo.de",
    },
  ],
};

</script>
