import { OpenFeature, ProviderEvents, InMemoryProvider } from '@openfeature/web-sdk';
import { FlagdWebProvider } from "@openfeature/flagd-web-provider"
// import { OFREPWebProvider } from '@openfeature/ofrep-web-provider';
import version from '~/package.json';
import { useLocalStorage, useSessionStorage } from '@vueuse/core';

export default defineNuxtPlugin((nuxtApp) => {
    console.debug('openfeature init', OpenFeature.getClient().providerStatus)

    $fetch('/api/flagd/defaults').then(defaultOpenFeatureFlags => {

        if (useRuntimeConfig()?.public?.openfeature.provider == 'flagd-web' && !useDevice().isCrawler) {
            let hostURL = useRequestURL();
            try {
                hostURL = new URL(useRuntimeConfig()?.public?.apiUrl)
            } catch (error) { }
            let settings = {
                host: hostURL?.hostname,
                port: Number(useRequestURL()?.port || '443'),
                pathPrefix: 'api/flagd',
                tls: (hostURL?.protocol == 'https:')
            };
            console.debug('using flagd-web', settings)
            OpenFeature.setProviderAndWait(useRuntimeConfig()?.public?.cookieDomain, new FlagdWebProvider(
                settings
            ))
        } else {
            console.debug('using default inmemory')
            OpenFeature.setProviderAndWait(useRuntimeConfig()?.public?.cookieDomain, new InMemoryProvider(defaultOpenFeatureFlags));
        }
        

        // // OpenFeature API

        OpenFeature.addHandler(ProviderEvents.Ready, (eventDetails) => {
            console.debug(`Ready event from: ${eventDetails?.providerName}:`, eventDetails);
            Array.from(useFlagsUsedState()?.value).forEach(feat => {
                if (eventDetails?.providerName == 'flagd-web') {
                    console.debug('updating', feat)
                    updateFlag(feat, defaultOpenFeatureFlags);
                }
            })

        });
        OpenFeature.addHandler(ProviderEvents.Reconciling, (eventDetails) => {
            console.debug(`Reconciling event from: ${eventDetails?.providerName}:`, eventDetails);
        });
        OpenFeature.addHandler(ProviderEvents.ContextChanged, (eventDetails) => {
            let cookie: Record<string, any> = unref(useABConfigCookie())?.context ?? {};
            if (cookie['targetingKey'] == 'bt_undefined')
                delete cookie['targetingKey'];
            let context = OpenFeature.getContext();
            if (context['targetingKey'] == 'bt_undefined')
                delete context['targetingKey'];
            let combined = { context: { ...context, ...cookie }, states: createFlagState() };
            useLocalStorage('expertShopABConfig', combined).value = combined;
            console.debug(`ContextChanged event from: ${eventDetails?.providerName}:`, eventDetails, { contextValue: context });
            useABConfigCookie().value = { context: { ...context, ...cookie } };
            // refreshCookie('expertShopABConfig');
        });
        // }
        OpenFeature.addHandler(ProviderEvents.ConfigurationChanged, (eventDetails) => {
            console.debug(`Config Changed event from: ${eventDetails?.providerName}:`, eventDetails);
            if (Array.isArray(eventDetails?.flagsChanged)) {
                eventDetails?.flagsChanged?.forEach(flagName => updateFlag(flagName, defaultOpenFeatureFlags));
            } else {
                Array.from(useFlagsUsedState()?.value).forEach(feat => {
                    if (eventDetails?.providerName == 'flagd-web') {
                        console.debug('updating', feat)
                        updateFlag(feat, defaultOpenFeatureFlags);
                    }
                })
            }
        })
        OpenFeature.addHandler(ProviderEvents.Stale, (eventDetails) => {
            console.debug(`Stale event from: ${eventDetails?.providerName}:`, eventDetails);
            // if (Array.isArray(eventDetails?.flagsChanged)) {
            //     eventDetails?.flagsChanged?.forEach(flagName => updateFlag(flagName, defaultOpenFeatureFlags));
            // }
        })

        OpenFeature.setContext({
            ...useABConfigCookie().value?.context,
            targetingKey: useExpCookieIdentifier().value ?? 'bt_undefined',
            version: version?.version,
            build: useRuntimeConfig()?.public?.version?.build,
            ismobile: useDevice()?.isMobile,
            useragent: useDevice()?.userAgent,
            storeid: useStoreCookie()?.value,
            usergroup: useUserGroup()?.value,
            lastseenonline: Date.now().toString()
        })

    });

    console.debug('got', OpenFeature.getClient(), OpenFeature.getContext())

    return {
        provide: {
            openfeature: OpenFeature
        }
    }
})

function updateFlag(flagName: string, defaultFlags?: any) {
    let lowerCaseName = flagName?.toLowerCase();
    let ddef = defaultFlags?.[lowerCaseName]?.disabled != undefined ? (defaultFlags?.[lowerCaseName]?.disabled == false) : false;
    let boolVal = OpenFeature.getClient(useRuntimeConfig()?.public?.cookieDomain).getBooleanValue(lowerCaseName, ddef);
    console.info(`${flagName} = ${boolVal}`)
    useFeatureState(lowerCaseName).value = boolVal;
    let details = OpenFeature.getClient(useRuntimeConfig()?.public?.cookieDomain).getObjectDetails(lowerCaseName, defaultFlags?.[lowerCaseName]?.variants?.[defaultFlags?.[lowerCaseName]?.defaultVariant]);
    useFeatureValue(lowerCaseName).value = details.value;
    useFlagVariants().value.set(lowerCaseName, details?.variant ?? 'default');
    useLocalStorage('expertShopABConfig', { states: {} }).value.states[flagName] = {
        enabled: boolVal,
        value: details?.value,
        variant: details?.variant ?? 'default'
    };
    console.debug(lowerCaseName + ' - changed', boolVal, details)
}

function createFlagState() {
    let res: any = {}
    try {
        useFlagsUsedState().value.forEach(ent => {
            res[ent] = { enabled: useNuxtApp()?.payload.state['$sfeature-' + ent], value: useNuxtApp()?.payload.state['$sfeature-value-' + ent], variant: useFlagVariants()?.value?.get(ent) ?? 'default' }
        });
    } catch (error) {

    }
    return res;
}