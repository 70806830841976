import { getExpNavigationTree } from "~/composables/ExpApi/expCache";

import { isProxy, toRaw, toValue } from 'vue';

export function getSubCategoriesFirstLevel(categoryId: string, storeId: string, limit: number) {
    return useFetch(useRuntimeConfig().public.apiUrl + '/api/neo/article-service/getSubCategoriesFirstLevel', {
        method: 'POST',
        body: {
            categoryId: categoryId,
            storeId: storeId,
            context: 'de_DE|SHOP'
        }, transform(data) {
            // console.debug('[bt-expCategory] got data: ' + JSON.stringify(data));
            if (data !== undefined && Array.isArray(data))
                return data.splice(0, (limit > data.length ? data.length : limit));
        }
    })
}

export async function getCategoryFromTree(categoryId: string) {
    // console.debug(`[bt-expCategory] fetching Categories for categoryId ${categoryId}`);
    const { data: navigationTree } = await getExpNavigationTree("MAIN");

    let primaryCat = navigationTree?.value?.find((cats: { extId: string }) => cats.extId == categoryId);
    if (primaryCat) {
        //wow
    }
    const allTheDs = navigationTree?.value?.flatMap((topCat) => topCat.descendants);
    const iHaveToGoDeeper = allTheDs?.flatMap(deep => deep.descendants);
    primaryCat = allTheDs?.find((daCat) => daCat.extId == categoryId)
    if (!primaryCat) {
        primaryCat = iHaveToGoDeeper?.find((daCat) => daCat.extId == categoryId);
    }
    let result = isProxy(primaryCat) ? toRaw(primaryCat) : primaryCat
    // console.debug('returning cat ', result)
    return result;
}

export function getCategoryFromNavigationTree(categoryId: string, navigationTree) {
    // console.debug(`[bt-expCategory] fetching Categories for categoryId ${categoryId}`);
    // const { error, data: navigationTree } = await getExpNavigationTree("MAIN");
    // if(error?.value){
    //     console.error('[bt-expCategory] ', error.value);
    //     return '-';
    // }

    let primaryCat = toValue(navigationTree)?.find((cats: { extId: string }) => cats.extId == categoryId);
    if (primaryCat) {
        //wow
        return isProxy(primaryCat) ? toRaw(primaryCat) : primaryCat;
    }
    const allTheDs = toValue(navigationTree)?.flatMap((topCat) => topCat.descendants);
    const iHaveToGoDeeper = allTheDs?.flatMap(deep => deep.descendants);
    primaryCat = allTheDs?.find((daCat) => daCat.extId == categoryId)
    if (!primaryCat) {
        primaryCat = iHaveToGoDeeper?.find((daCat) => daCat.extId == categoryId);
    }
    let result = isProxy(primaryCat) ? toRaw(primaryCat) : primaryCat
    // console.debug('returning cat ', result)
    return result;
}

export function getCategoryFromNavigationTreeByName(name: string, navigationTree) {

    let primaryCat = toValue(navigationTree)?.find((cats: { name: string }) => cats.name == name);
    if (primaryCat) {
        //wow
        return isProxy(primaryCat) ? toRaw(primaryCat) : primaryCat;
    }
    const allTheDs = toValue(navigationTree)?.flatMap((topCat) => topCat.descendants);
    const iHaveToGoDeeper = allTheDs?.flatMap(deep => deep.descendants);
    primaryCat = allTheDs?.find((daCat) => daCat.name == name)
    if (!primaryCat) {
        primaryCat = iHaveToGoDeeper?.find((daCat) => daCat.name == name);
    }
    let result = isProxy(primaryCat) ? toRaw(primaryCat) : primaryCat
    // console.debug('returning cat ', result)
    return result;
}

export const getValidSeoBanners = (bannerList: Array<any>, storeId: string): Array<any> => {
    const currentTimestamp = Date.now();

    const validBanners: Array<any> = bannerList?.filter(slide => {
        const inValidTime: boolean = slide?.validTo >= currentTimestamp && slide?.validFrom <= currentTimestamp;
        const hasNoTimeValidation: boolean = (slide?.validFrom === 0 && slide?.validTo === 0) || (!slide?.validFrom && !slide?.validTo);

        const visibleForAllStores: boolean = slide?.supportedStores === null || slide?.supportedStores?.length === 0;
        const visibleForCurrentStore: boolean = slide?.supportedStores?.includes(storeId);

        return (inValidTime || hasNoTimeValidation) && (visibleForCurrentStore || visibleForAllStores);
    });

    return validBanners ?? [];
}