import type { Promotion, PromotionBubble } from "~/models/Promotions";

export const promotionToBubble = (promotions: Array<Promotion>): Array<PromotionBubble> => {
    return promotions.map(promotion => {
        return {
            id: promotion.id,
            title: promotion.title,
            teaser: promotion.teaser,
        };
    });
}