<template>
  <div class="storeFinder entity-list">
    <template v-for="(entry, index) in storeList" :key="index">
      <div class="entity active" :class="(index === selected ? ' selected':'')" @click="selectNewLocalStore(index)">
        <div><img class="w-3" src="~/assets/mediums/Icons/map-pin-solid.svg" /></div>
        <div class="location">
          <div class="address">
            <div class="title">
              <div>{{ entry.store.name }}</div>
              <div class="distance">
                <BasicIcon :name="'location-dot-solid'" class="w-4 text-secondary" />&nbsp;{{ entry.linearDistance }} km
              </div>
            </div>
            <div>{{ entry.store.street }}</div>
            <div>{{ entry.store.zip }} {{ entry.store.city }}</div>
            <div v-if="index === selected">{{ entry.store.phone }}</div>
            <div v-html="getCurrentOpenings(entry.store)" />
            <a v-if="index !== selected" class="text-primary cursor-pointer">weitere Details</a>
          </div>
          <div class="opening-times" v-if="index === selected">
            <template v-for="days in Object.keys(entry.openingTimes)" :key="days">
              <strong style="text-transform: capitalize;">{{ days }}</strong>
              <template v-for="times in entry.openingTimes[days].times" :key="times">
                <div>{{ times }}</div>
              </template>
            </template>
          </div>
          <template v-if="storeRef?.currentStore.value.id !== entry.store.id">
            <div class="assign-trigger" v-if="index === selected">
              <button @click="changeLocalStoreInCookie(entry.store.id)">Markt auswählen ></button>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
const props = defineProps({
  storeList: {}
})

const emit = defineEmits(['clickedOnStore', 'storeChanged', 'selectStore'])


const storeRef = inject(ProvideNames.CURRENT_STORE_KEY);
const updateFunction = storeRef?.updateFunction || console.error;
const selected = ref(0)
function selectNewLocalStore(index: number) {
  selected.value = index
  emit('clickedOnStore', props?.storeList?.at(selected.value).store);
}

function selectStore(marker: any) {
  for(let i = 0; i < props?.storeList?.length; i++) {
    if(props?.storeList[i].store?.id == marker.id) {
      selectNewLocalStore(i)
      setTimeout(function(){
        document.querySelector('.storeFinder.entity-list .entity.selected').scrollIntoView()
      })
      break;
    }
  }
}
defineExpose({selectStore})

async function changeLocalStoreInCookie(storeId: number) {
  try {
    emit('storeChanged');
    await useFetch('/api/neo/frontend/_api/storeFinder/assignStore', {
      method: 'POST',
      body: JSON.stringify({
        storeId: storeId
      }),
    });
    await useFetch('/api/neo/frontend/_api/user/getCsrfToken', {
      method: 'GET',
      query: {
        branch_id: storeId
      },
      headers: {
        "bt-use-user-auth": "true"
      }
    });

    clearNuxtData('expUserProfile')
    clearNuxtData('currentReservationCart')
    clearNuxtData('currentShoppingCart')


    nextTick(() => {
      updateFunction(storeId);
      console.log("update function called");
    })
  } catch (error) {
    console.warn("could not change store");
  }
}

function getTimeString(timeInt) {
  var hours = Math.floor(timeInt / 60);
  hours = hours + "";
  while (hours.length < 2) hours = "0" + hours;
  var minutes = timeInt % 60;
  minutes = minutes + "";
  while (minutes.length < 2) minutes = "0" + minutes;
  return hours + ":" + minutes;
}			

function getCurrentOpenings(store){
  const days = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  let openings = store.openingHours;
  let currentDate = new Date();
  let today = currentDate.getDay() - 1;
  let now = currentDate.getHours() * 60 + currentDate.getMinutes();
  let firstOpenToday = null;
  let nextOpenToday = 1441;
  let lastOpenToday = null;
  let notOnBreak = false;
  for (let i = 0; i < openings?.length; i++) {
    let opening = openings[i];
    if (
      days.indexOf(opening.dayFrom) <= today &&
      today <= days.indexOf(opening.dayTo)
    ) {
      if (firstOpenToday == null || firstOpenToday > opening.timeFrom)
        firstOpenToday = opening.timeFrom;
      if (nextOpenToday > opening.timeFrom && opening.timeTo > now)
        nextOpenToday = opening.timeFrom;
      if (lastOpenToday == null || lastOpenToday < opening.timeTo)
        lastOpenToday = opening.timeTo;
      if (opening.timeFrom <= now && now <= opening.timeTo)
        notOnBreak = true;
    }
  }
  if (firstOpenToday == null || lastOpenToday == null) {
    return "Heute geschlossen!";
  }
  if (firstOpenToday <= now && now <= lastOpenToday) {
    if (notOnBreak)
      return "<div class='w-2.5 h-2.5 mr-1.5 inline-block rounded-3xl bg-green-700'></div>Geöffnet - schließt um " + getTimeString(lastOpenToday) + " Uhr";
    else return "<div class='w-2.5 h-2.5 mr-1.5 inline-block rounded-3xl bg-red-700'></div>Geschlossen - öffnet um " + getTimeString(nextOpenToday) + " Uhr";
  } else if (now < firstOpenToday)
    return "<div class='w-2.5 h-2.5 mr-1.5 inline-block rounded-3xl bg-red-700'></div>Geschlossen - öffnet um " + getTimeString(firstOpenToday) + " Uhr";
  else return "<div class='w-2.5 h-2.5 mr-1.5 inline-block rounded-3xl bg-red-700'></div> Heute bereits geschlossen!";
}

</script>