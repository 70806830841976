<template>
  <div>
    <div class="promo-bubble" v-if="teaser != undefined">
      <NuxtImg :src="teaser.originalData" preset="promo_big" :alt="teaser.alt" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IPrice } from "~/models/ExpPrice/exp_Price";
import { promotionToBubble } from "~/composables/Mapper/Promotion";
import { getTopPromotionWithFreeShipping } from "~/composables/promotions";

interface Props {
  articleId: string;
  price: IPrice;
  listIndex?: number;
}

const props = withDefaults(defineProps<Props>(), {
  listIndex: 0,
});

const teaser = ref();

watchEffect(() => {
  let filteredList = getTopPromotionWithFreeShipping(useActivePromotions()?.value, props.articleId, props.price);
  const promotionBubble = promotionToBubble(filteredList).at(props.listIndex);

  teaser.value = promotionBubble?.teaser;
});
</script>

<style>
.promo-bubble {
  transition: transform 0.2s;
  max-width: 60px;
  max-height: 60px;
}

.promo-bubble:hover {
  transform: scale(2);
  opacity: 1;
}
</style>
