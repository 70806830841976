<template>
	<template v-if="props?.warrantyList">
		<div class="warranty" v-for="entry in props?.warrantyList.warrantyList" :key="entry">
			<div v-if="showInput">
				<input type="radio" name="warrantyCheckbox" :id="entry.title" @click="changeInputState(entry)" />
			</div>
			<div class="entry" @click="changeInputState(entry)">
				<strong class="title">{{ entry.title }}</strong>
				<template
					v-if="entry.monthlyBruttoPrice > 0 || entry.bruttoPrice === null || entry.bruttoPrice === undefined"
>
					<div class="price">
						{{ props?.mainArticleQuantity > 1 ? (props?.mainArticleQuantity *
		entry.monthlyBruttoPrice).toFixed(2) : entry.monthlyBruttoPrice }} € pro Monat
					</div>
				</template>
				<template v-if="entry.bruttoPrice > 0 && entry.bruttoPrice !== null">
					<div class='price'>{{props?.mainArticleQuantity > 1 ? (props?.mainArticleQuantity * entry.bruttoPrice).toFixed(2) : entry.bruttoPrice}} € einmalig</div>
				</template>
				<div class="info">
					<img
alt="Info" class="ic-info" @click.stop="openWarrantyInformation(entry)"
						src="~/assets/mediums/Icons/circle-info-light.svg"
/>
				</div>
				<Modal v-if="showWarranty == entry.title" @close-modal="showWarranty = false">
					<template #icon>
						x
					</template>
					<strong>{{ warrantyTitle }}</strong>
					<p>{{ warrantyDescription }}</p>
					<div v-for="files in warrantyUrl" :key="files">
						<a :href="files.fileUrl" class="warranty-link" target="_blank" :title="warrantyTitle">{{
		files.fileName }}</a>
					</div>
					<ArticleExpMedium mediumId="65f9af5be7b128000186bddc"></ArticleExpMedium>
				</Modal>
			</div>
		</div>
	</template>
</template>
<script setup lang='ts'>
import Modal from './Modal.vue';
const props = defineProps({
	showInput: {
		type: Boolean
	},
	taxLabel: {
		type: String
	},
	warrantyList: {
		type: Object,
	},
	showWarranty: {
		type: String
	},
	mainArticleQuantity: {
		type: Number
	}
})
const showWarranty = ref(props?.showWarranty)
let currentTitle = ''
const emit = defineEmits(['checkboxCollection'])
function changeInputState(ob: Warranty) {
	if (props?.showInput) {
		const activatedInputs = []
		if (currentTitle === ob.title) {
			ob.source = 'WG'
			emit('checkboxCollection', { key: ob, value: false })
			currentTitle = ''
			document.getElementById(ob.title).checked = false
		} else {
			currentTitle = ob.title
			document.getElementById(ob.title).checked = true
			ob.source = 'WG'
			activatedInputs.push(ob)
			emit('checkboxCollection', { key: ob, value: true })
		}
	}
}

// Gobal Parameters for filling PopUp with content
let warrantyTitle = ''
let warrantyDescription = ''
let warrantyUrl = new Array()
function openWarrantyInformation(warrantyEntry: Warranty) {
	showWarranty.value = warrantyEntry.title
	warrantyTitle = warrantyEntry.title
	warrantyDescription = warrantyEntry.description
	const ob = {}
	const urlCollection = new Array()
	for (let a = 0; a < warrantyEntry.listOfFiles.list.length; a++) {
		ob.fileName = warrantyEntry.listOfFiles.list[a].fileName
		ob.fileUrl = warrantyEntry.listOfFiles.list[a].fileUrl
		urlCollection.push(ob)
	}
	warrantyUrl = urlCollection
}

export declare interface Warranty {
	title: string
	bruttoPrice: number
	currency: string
	taxRate: number
	monthlyBruttoPrice: number
	monthlyTaxRate: number
	listOfFiles: any[] | undefined
	description: string
	typeName: string
}

</script>
<style>
.warranty input[type=radio] {
	appearance: none;
	background-color: #fff;
	width: 15px;
	height: 15px;
	border: 2px solid #ccc;
	border-radius: 2px;
	display: inline-grid;
	place-content: center;
}

.warranty input[type=radio]::before {
	content: "";
	width: 10px;
	height: 10px;
	transform: scale(0);
	transform-origin: bottom left;
	background-color: #fff;
	clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

.warranty input[type=radio]:checked::before {
	transform: scale(1);
}

.warranty input[type=radio]:checked {
	background-color: #0075FF;
	border: 2px solid #0075FF;
}
</style>
