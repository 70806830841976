import { getCategoryFromTree } from "~/composables/ExpApi/expCategory";
import { useCurrentStore, gtmDataContainer, useFlagsUsedState } from "~/composables/states";
import { useLocalStorage, useSessionStorage } from "@vueuse/core";
import { v4 as uuidv4 } from "uuid";
import { getExpBrand, getExpNavigationTree } from "./ExpApi/expCache";
import { getExpArticle } from "./ExpApi/expArticle";

// let dataLayerContainer = gtmDataContainer()
// let goblaCounter = gtmGlobalCounter()
export function fillGtmDataLayer(product: any, impressionList?: any) {
	if (product) {
		gtmDataContainer().value.ecommerce.detail.products = [product];
	}
	if (impressionList) {
		for (let b = 0; b < impressionList.length; b++) {
			gtmDataContainer().value.ecommerce.impressions.push(impressionList[b]);
		}
	}
}

export function checkTenParents(target, attribute: string) {
	let x = 0;
	let parent = target?.parentElement;
	while (x < 10 && !parent?.getAttribute(attribute)) {
		parent = parent?.parentElement;
		x++;
	}
	return parent?.getAttribute(attribute);
}

// export async function getPdsInfoForDataLayer(bundle: Object) {
// 	console.log("getPdsInfoForDataLayer : ",JSON.stringify(bundle))
// 	let brand = await getExpBrand(bundle?.brandId);
// 	let categoryList = await getArticleCategoriesList(bundle?.primaryCategory);
// 	console.log("brand gtm : ",brand)
// 	let ob: DLPdsInformation = {
// 		id: "",
// 		name: "",
// 		brand: "",
// 		category: "",
// 		price: "",
// 		priceNet: "",
// 		quantity: 0,
// 		id_branch_id: ""
// 	}
// 	ob.id = bundle.webcode
// 	ob.name = bundle.title
// 	ob.brand = brand.name
// 	ob.category = categoryList
// 	ob.price = bundle?.formerBruttoPrice
// 	// ob.priceNet = bundle?.
// }

export async function getImpressionsForDataLayer(bundle: Array<Object>) {
	// console.log("getImpressionsForDataLayer : ", bundle);
	let container = new Array();
	if (bundle !== undefined && bundle.length !== undefined) {
		for (let a = 0; a < bundle.length; a++) {
			let ob: DLImpressions = {
				id: "",
				name: "",
				list: "",
				brand: "",
				category: "",
				position: 0,
				price: "",
				priceNet: "",
				id_branch_id: "",
				ean: "",
			};
			ob.id = bundle[a].id;
			ob.name = bundle[a]?.expArticle?.title;
			ob.list = "PPM";
			ob.brand = bundle[a].brand;
			// let categoryList = await getArticleCategoriesList(bundle[a]?.expArticle?.primaryCategory);
			// if(categoryList !== undefined && categoryList?.value?.list.length > 0) {
			// 	let array = categoryList?.value?.list
			// 	let primaryCategory = array[array.length-1].name
			// 	ob.category = primaryCategory
			// }
			const cat = await getCategoryFromTree(bundle[a]?.expArticle?.primaryCategory);
			const catString = cat?.ancestors?.replace(", ", "/") + "/" + cat?.name;

			ob.category = catString;

			ob.position = gtmGlobalCounter().value;
			ob.price = bundle[a]?.price?.toString()?.slice(0, -3);
			ob.priceNet = bundle[a]?.expPrice?.nettoPrice?.toString()?.slice(0, -3);
			ob.id_branch_id = bundle[a]?.id + "_" + useCurrentStore().value?.id;
			ob.ean = bundle[a]?.expArticle?.internationalArticleNumber;

			container.push(ob);
			gtmGlobalCounter().value++;
		}
	}
	// console.log("getImpressionsForDataLayer :CONTAINER ", container);
	fillGtmDataLayer(false, container);
}

export const useGeneralTrick = (event: any, to?: any) => {
	console.debug("[bt-gta] got trick ", event?.eventType, event);
	const finalEvent = createGeneralTickingData(event, to);
	if (useDevice()?.isCrawler == false && useGtm()?.enabled()) {
		checkForGTMTracking(finalEvent);
	}
	// useState('generalTicking').value = finalEvent;
	// console.debug("[gta] got event", finalEvent);
	const params = {
		method: "POST",
		body: {
			// entry: {
			sessionId: event.sessionId,
			eventType: event.eventType,
			tickId: finalEvent?.tickId,
			json: JSON.stringify(finalEvent),
			// },
		},
		credentials: "include",
		onRequestError(err) {
			console.debug("[bt-gta] got request-error", err?.error, err?.response);
		},
		onResponse(context) {
			console.debug("[bt-gta] got response", context?.error, context?.response);
		},
	};
	// if (finalEvent?.eventType != 'VIEW_ITEM') {
	// if (useNuxtApp().isHydrating) {
	// 	console.warn('useFetch for Tracking')
	// 	return useFetch(useRuntimeConfig().public.apiUrl + "/api/generalTrick/tick", { body: params.body, method: "POST", credentials: "include", onRequestError: params.onRequestError, onResponse: params.onResponse });
	// } else {
	// console.warn('$fetch for Tracking')
	return $fetch(useRuntimeConfig().public.apiUrl + "/api/generalTrick/tick", { body: params.body, method: "POST", referrerPolicy: "origin", credentials: "include", onRequestError: params.onRequestError, onResponse: params.onResponse })
	// }
	// }
};

function checkForGTMTracking(finalEvent: any) {
	// console.debug('checking for GTM Tracking', finalEvent);
	if (finalEvent?.eventType == 'VIEW_ITEM') {
		// console.debug('got View Item Event, sending via gtm');
		useGtm()?.dataLayer()?.push({ ecommerce: null });  // Clear the previous ecommerce object.
		let ob = {
			id: "",
			name: "",
			brand: "",
			category: "",
			price: "",
			priceNet: "",
			quantity: 1,
			id_branch_id: "",
			ean: "",
		};
		ob.id = finalEvent?.json?.item;
		ob.name = finalEvent?.json?.name;
		ob.brand = finalEvent?.json?.brand;
		ob.category = finalEvent?.json?.category;
		ob.price = finalEvent?.json?.price;
		ob.priceNet = finalEvent?.json?.priceNet;
		ob.quantity = finalEvent?.json?.quantity;
		ob.id_branch_id = finalEvent?.json?.item + "_" + finalEvent?.storeId;
		ob.ean = finalEvent?.json?.ean;
		useGtm()?.trackEvent({
			event: "reload",
			ecommerce: {
				detail: {
					products: [ob],
				},
				impressions: toRaw(gtmDataContainer()?.value?.ecommerce?.impressions)
			}
		})
	} else if (finalEvent?.eventType == 'VIEW_PAGE' && (finalEvent?.json?.content?.startsWith('Checkout') || finalEvent?.json?.content?.startsWith('Reservation'))) {
		// console.debug('got Checkout Step Event, sending via gtm');
		useGtm()?.dataLayer()?.push({ ecommerce: null });  // Clear the previous ecommerce object.
		useGtm()?.trackEvent({
			event: "checkout",
			ecommerce: {
				checkout: {
					actionField: {
						step: finalEvent?.json?.step,
						option: finalEvent?.json?.paymentChannel
					},
					products: finalEvent?.json?.cart?.itemList?.map(itemToGTM),
				},
			}
		})
	}
}

export function itemToGTM(item) {
	console.debug('mapping item', item)
	const article = toRaw(useNuxtData('article/id=' + item?.articleId)?.data?.value);
	const brand = toRaw(useNuxtData('brand/id=' + article?.brandId)?.data?.value);
	// const primCat = Array.from(getExpNavigationTree('MAIN')?.data?.value)?.flatMap(ca => ca?.descendants)?.flatMap(ca => ca?.descendants)?.find(cat => cat?.extId == article?.primaryCategory);
	const primCat = toRaw(getExpNavigationTree('MAIN')?.data?.value?.flatMap(ca => ca?.descendants)?.flatMap(ca => ca?.descendants)?.find(cat => cat?.extId == article?.primaryCategory));
	let gtmItem = {
		name: item?.name || article?.name,
		brand: brand?.name,
		id: article?.webcode,
		price: item?.price?.gross,
		priceNet: item?.price?.net,
		quantity: item?.quantity,
		category: primCat?.ancestors?.replace(', ', '/') + '/' + primCat?.name,
		id_branch_id: item?.articleId + '_' + useCurrentStore()?.value?.id,
		ean: article?.internationalArticleNumber
	}
	return gtmItem;
}


interface DLImpressions {
	id: string;
	name: string;
	list: string;
	brand: string;
	category: string;
	position: number;
	price: string;
	priceNet: string;
	id_branch_id: string;
	ean: string;
}

export interface DLPdsInformation {
	id: string;
	name: string;
	brand: string;
	category: string;
	price: string;
	priceNet: string;
	quantity: number;
	id_branch_id: string;
	ean: string;
}

export function sendUiStateToSearchEvent(uiState, user) {
	// console.debug("[bt-gta] useUiStateToSearchEvent", uiState, user);
	return useGeneralTrick({
		eventType: "VIEW_SEARCH_RESULT",
		sessionId: user?.sessionIdentifier,
		json: {
			search: [uiState?.query, uiState?.totalHits],
			usertype: "unknownUser",
			langid: 'userbotscore=' + useABConfigCookie()?.value?.context?.userbotscore,
			countryid: user?.client,
			content: useRoute().path,
			url: [[useRoute().fullPath]],
			redirectedFrom: useRoute().redirectedFrom,
			sfilter: [["queryType", uiState?.queryType]],
			refinements: uiState?.refinementList,
			configure: uiState?.configure,
			sortBy: uiState?.sortBy,
			processingTimeMS: uiState?.processingTimeMS,
		},
	});
}

export function middleware(instantSearchInstance) {
	return {
		onStateChange({ uiState }) {
			// console.debug("ui state changed, send event", uiState, instantSearchInstance);
			if (uiState[useReactiveSearchCreds().reactiveBaseAppName]) {
				uiState[useReactiveSearchCreds().reactiveBaseAppName].totalHits =
					instantSearchInstance.renderState?.[useReactiveSearchCreds().reactiveBaseAppName].hits.results.nbHits;
				uiState[useReactiveSearchCreds().reactiveBaseAppName].queryType =
					instantSearchInstance.renderState?.[useReactiveSearchCreds().reactiveBaseAppName].hits.results.queryType;
				uiState[useReactiveSearchCreds().reactiveBaseAppName].processingTime =
					instantSearchInstance.renderState?.[useReactiveSearchCreds().reactiveBaseAppName].hits.results.processingTimeMS;
			}
			// return sendUiStateToSearchEvent(uiState[useReactiveSearchCreds().reactiveBaseAppName], useState("userProfile").value);
		},
		subscribe() {
			instantSearchInstance;
		},
	};
}

export const useEmosMarker = (marker: { name: string, content: string }) => {
	try {
		onNuxtReady(() => {
			console.debug('sending marker', marker)
			try {
				useNuxtApp()?.$emos3?.send({
					'marker': marker?.name,
					'content': marker?.content
				})
			} catch (error) {
				console.error('not send marker', error);
			}
		})
	} catch (error) {
		console.error('could not send marker', error);
	}
}

export const useMarker = (marker: string) => {
	let markerStrings = useNuxtApp()?.$openfeature?.getContext().markers || "";
	const lastMarkers = markerStrings?.toString().split('|');
	if (lastMarkers.length > 4) {
		lastMarkers.shift();
	}
	lastMarkers.push(marker);
	useLastActions()?.value?.push(marker);
	useNuxtApp()?.$openfeature.setContext({ markers: lastMarkers?.join('|') });
}

export const useLastActions = () => useState<Array<string>>("lastActions", () => new Array<string>());

export const useTrickOnce = (eventType: string, json: any) => {
	const resW = watchEffect(async () => {
		if (useCurrentSessionIdentifier().value) {
			// console.debug('ready to trick');
			await useGeneralTrick({
				eventType: eventType,
				sessionId: useCurrentSessionIdentifier()?.value,
				json: json,
			});
			// console.log("[bt-general-tick] ticking", res);
			resW();
		}
	});
}

export function createGeneralTickingData(event: any, to?: any) {

	const emos_jcvid = import.meta.client ? localStorage.getItem("emos_jcvid") : undefined || useCookie('emos-visitor').value;
	const emos_jcsid = import.meta.client ? localStorage.getItem("emos_jcsid") : undefined || import.meta.client ? sessionStorage.getItem("emos_jcsid") : undefined || useCookie('emos-visitor').value;
	const emos_privacy = import.meta.client ? localStorage.getItem("emos_privacy") : undefined
	const emos_jckamp = useCookie("emos_jckamp").value || import.meta.client ? localStorage.getItem("emos_jckamp") : undefined;

	// const traits = {...useABConfigCookie()?.value};
	const states = createFlagState();
	let genericData = {
		tickId: uuidv4(),
		storeId: useCurrentStore()?.value?.id,
		companyGroup: useCurrentStore()?.value?.companyGroupId,
		userAgent: useDevice()?.userAgent,
		url: to?.fullPath || useRoute().fullPath,
		route: { ...to, matched: undefined },
		emosVid: emos_jcvid,
		emosSid: emos_jcsid,
		emosPrv: emos_privacy,
		emosKmp: emos_jckamp,
		flagStates: states,
		// traits: traits,
	};

	const finalEvent = { ...genericData, ...event };
	// console.debug('ticking', finalEvent)
	return finalEvent;
}

function createFlagState() {
	let res: any = {}
	try {
		useFlagsUsedState().value.forEach(ent => {
			res[ent] = { enabled: useNuxtApp()?.payload.state['$sfeature-' + ent], value: useNuxtApp()?.payload.state['$sfeature-value-' + ent], variant: useFlagVariants()?.value?.get(ent) ?? 'default' }
		});
	} catch (error) {

	}
	return res;
}

export function gtag() {
	useGtm()?.dataLayer().push(arguments);
}