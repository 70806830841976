import type { OrderModification, Promotion } from "~/models/Promotions";
import type { IPrice } from "~/models/ExpPrice/exp_Price";

export const getMatchingPromotions = (promotions: Array<Promotion>, articleId: string, price: IPrice): Array<Promotion> => {
    const filteredPromotions = promotions?.filter(promotion => {
        const matchingArticle = promotion.orderModification.find(modification => orderModificationFilter(modification, articleId, price));

        return matchingArticle !== undefined && promotion?.teaser?.originalData !== undefined;
    });

    return filteredPromotions ?? [];
}

export const getTopPromotions = (promotions: Array<Promotion>, articleId: string, price: IPrice): Array<Promotion> => {
    let topPromotions = getMatchingPromotions(promotions, articleId, price);
    topPromotions = topPromotions.sort((a, b) => a.priority - b.priority);

    return topPromotions.length > 0 ? topPromotions : [];
}

export const getTopPromotionWithFreeShipping = (promotions: Array<Promotion>, articleId: string, price: IPrice): Array<Promotion> => {
    let topPromotions = getTopPromotions(promotions, articleId, price);
    topPromotions = addFreeShippingPromotionIfAvailable(topPromotions, price);
    return topPromotions.sort((a, b) => a.priority - b.priority);
}

const orderModificationFilter = (modification: OrderModification, articleId: string, price: IPrice) => {
    const onlineAvailabilityArray = ['AVAILABLE', 'NEXT_DAYS_AVAILABLE', 'PREORDER', 'NOT_IN_STORE'];
    const storeAvailabilityArray = ['AVAILABLE', 'ONLY_IN_STORE'];

    const matchesAllArticles = modification.affectedArticles?.includes('*') || modification.bubbleArticles?.includes('*');
    const matchesArticle = modification.affectedArticles?.includes(articleId) || modification.bubbleArticles?.includes(articleId);

    let matchOnlineAvailability = true;
    if (modification.showBubbleOnlyWhenOnlineAvailable && price?.onlineAvailability) {
        matchOnlineAvailability = onlineAvailabilityArray.includes(price.onlineAvailability);
    }

    let matchStoreAvailability = true;
    if (modification.showBubbleOnlyWhenStoreAvailable && price?.storeAvailability) {
        matchStoreAvailability = storeAvailabilityArray.includes(price.storeAvailability);
    }

    const matchesAllStores = !modification.stores || modification.stores?.length === 0;
    const matchesStore = modification?.stores?.includes(useCurrentStore().value?.id);

    return (matchesArticle || matchesAllArticles) && (matchOnlineAvailability && matchStoreAvailability) && (matchesAllStores || matchesStore);
}

export const addFreeShippingPromotionIfAvailable = (promotions: Array<Promotion>, price: IPrice): Array<Promotion> => {
    const newPromotions: Array<Promotion> = promotions;
    if (price?.shipmentArray && price?.shipmentArray?.[0]?.shipmentBruttoPrice === 0) {
        newPromotions.push({
            id: 'VSK',
            priority: -10000,
            teaser: {
                originalData: 'ae/e2/19/7c4fee3cfa70e4d1a0ab3ed075c1871a41/Versandkostenfrei_FMS_1000x1000.png',
                alt: 'Versandkostenfrei',
                title: 'Versandkostenfrei'
            }
        });
    }

    return newPromotions;
}